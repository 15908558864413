<template>
  <view
    :class="showPage == '01' ? 'bg': showPage == '02' ? 'bg2': showPage == '03' ? 'bg3': 'bg4'"
    @click="clickToWx"
  >
	  <img src="./images/get.gif" alt="" class="button">
  </view>
</template>

<script setup>
import { useRoute } from "vue-router";
import {onMounted, ref} from "vue";
const route = useRoute();
let showPage = ref(1);
onMounted(() => {
  showPage.value = route.query.wchat;
	console.log(showPage.value);
});
function clickToWx() {
  window.location.href = "https://work.weixin.qq.com/ca/cawcde36b05d6dc27b";
}
</script>

<style scoped lang="less">
.bg {
	margin: 0 auto;
	width: 750px;
	height: 4792px;
  background-image: url(./images/img1.png);
  background-size: cover;
	display: block;
}
.bg2 {
	margin: 0 auto;
	width: 750px;
	height: 3395px;
  background-image: url(./images/img2.png);
  background-size: cover;
	display: block;
}
.bg3 {
	margin: 0 auto;
	width: 750px;
	height: 3395px;
  background-image: url(./images/img3.png);
  background-size: cover;
	display: block;
}
.bg4 {
	margin: 0 auto;
	width: 750px;
	height: 3395px;
  background-image: url(./images/img4.png);
  background-size: cover;
	display: block;
}
.button {
	position: relative;
	top: 369px;
  margin: 0 auto;
  width: 375px;
  height: 100px;
	display: block;
}
</style>
